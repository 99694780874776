type charityInfo = {
  title: string;
  link: string;
};
export const charity0 = {
  title: "SPCA",
  link: "https://givealittle.co.nz/fundraiser/sequenteers-raising-money-through-cooperation",
} as charityInfo;
export const charity1 = {
  title: "Aviva (Womens Refuge Christchurch)",
  link: "https://givealittle.co.nz/fundraiser/sequenteers-raising-money-through-cooperation-1",
} as charityInfo;
export const charity2 = {
  title: "Life Matters Suicide Prevention Trust",
  link: "https://givealittle.co.nz/fundraiser/sequenteers-raising-money-through-cooperation-2",
} as charityInfo;
export const charity3 = {
  title: "Big Brothers Big Sisters (Youth Support)",
  link: "https://givealittle.co.nz/fundraiser/sequenteers-raising-money-through-cooperation-big",
};
export const daysShown = 30;
export const seequentHomeUrl = "https://www.seequent.com";

export const parkSeeqDocUrl = "https://engage.bentley.com/parkseeqapp/?w=aa596";
export const tosUrl =
  "https://engage.bentley.com/parkseeqapp/items/terms-of-use-parkseeq---draftpdf?w=aa596";
